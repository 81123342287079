import { uuidv4 } from "@firebase/util";
import { getAuth } from "firebase/auth";
import { NextPageContext } from "next";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import Image from "next/image";
import { MartEyeLogoPopout } from "../components/logos/standard";
import bgImage from "../images/login/login-hills.jpg";

// Google Providers
import AppleLogin from "@/components/login/apple";
import EmailLogin from "@/components/login/email";
import GoogleLogin from "@/components/login/google";

import Head from "next/head";

export default function Login(props: { csrfToken: string }) {
  let router = useRouter();
  let [authHasLoaded, setAuthHasLoaded] = useState(false);
  let [isGettingAuthCookie, setIsGettingAuthCookie] = useState(false);

  let getAuthCookie = async () => {
    if (isGettingAuthCookie) {
      return;
    }
    setIsGettingAuthCookie(true);
    console.log("getting auth cookie");
    try {
      let idToken = await getAuth().currentUser?.getIdToken();
      let body = {
        csrfToken: props.csrfToken,
        idToken: idToken,
      };

      let loginResult = await fetch("/api/__session", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      if (loginResult.status !== 200) {
        console.error("Login failed", loginResult);
        setIsGettingAuthCookie(false);
        return;
      }

      console.log("redirecting");
      router.replace("/");
    } catch (e) {
      setIsGettingAuthCookie(false);
      console.error(e);
    }
  };

  useEffect(() => {
    // its possible the user's cookie expired but they are still logged in client side
    // check for that.
    let unsub = getAuth().onAuthStateChanged(async (user) => {
      setAuthHasLoaded(true);

      if (user) {
        getAuthCookie();
      }
    });

    return () => {
      unsub();
    };
  }, []);

  let showLoginForm = !isGettingAuthCookie && authHasLoaded;

  return (
    <>
      <Head>
        <title>Login | MartEye Studio</title>
        {/*

      TODO

      - Add SEO meta tags 

      */}
      </Head>

      <div className="h-full w-full">
        <div className="flex min-h-full">
          <div className="flex flex-1 flex-col justify-center bg-white py-12 px-4 drop-shadow-md sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div className="mx-auto w-full max-w-sm lg:w-96 ">
              <div className="w-full">
                <MartEyeLogoPopout className="h-16 w-auto" />
              </div>
              {showLoginForm ? (
                <div className="mt-6">
                  <div>
                    <div>
                      <p className="text-sm font-medium leading-6 text-martEye-600">
                        Sign in with
                      </p>

                      <div className="mt-2 grid grid-cols-2 gap-3">
                        <div>
                          <AppleLogin />
                        </div>
                        <div>
                          <GoogleLogin />
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-6">
                      <div
                        className="absolute inset-0 flex items-center"
                        aria-hidden="true"
                      >
                        <div className="w-full border-t border-martEye-600" />
                      </div>
                      <div className="relative flex justify-center text-sm">
                        <span className="bg-white px-2 text-martEye-600">
                          or sign in with
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6">
                    <EmailLogin />
                  </div>
                  <div>
                    {/* TODO add other links ? 
                - Privacy Policy?
                - Contact us?
                */}
                  </div>
                </div>
              ) : (
                <div className="mt-6">
                  <div>
                    <div>
                      <p className="text-sm font-medium leading-6 text-martEye-600">
                        Signing in
                      </p>

                      <div className="mt-2 flex justify-center items-center p-8">
                        {/* Show a spinner */}
                        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-martEye-600"></div>
                      </div>
                    </div>
                  </div>

                  <div>
                    {/* TODO add other links ? 
                - Privacy Policy?
                - Contact us?
                */}
                  </div>
                </div>
              )}

              <div className="relative mt-5  md:mt-0 md:absolute md:bottom-5">
                <p className="text-xs leading-5 text-martEye-600">
                  Copyright © 2023 MartEye Limited.
                </p>
              </div>
            </div>
          </div>

          <div className="relative hidden w-0 flex-1 lg:block">
            <Image
              src={bgImage}
              className="absolute inset-0 h-full w-full object-cover"
              role="decorative"
              alt=""
              fill={true}
            />
          </div>
        </div>
      </div>
    </>
  );
}

// server props get headers
export async function getServerSideProps(context: NextPageContext) {
  let domain = "";
  // if the request was from the studio domain allow the cookie to be used for all subdomains (e.g. api.marteyestudio.com)
  if (
    context.req?.headers["host"] &&
    context.req?.headers["host"].endsWith("marteyestudio.com")
  ) {
    domain = " domain=marteyestudio.com;";
  }

  let csrfToken = uuidv4();
  // set it in the header
  context.res?.setHeader("Set-Cookie", [
    `__session=; HttpOnly; Secure; SameSite=Strict; Max-Age=0; Path=/; ${domain}`,
    `csrfToken=${csrfToken}; Path=/; HttpOnly; Secure; SameSite=Strict; Max-Age=600`,
  ]);

  return {
    props: {
      csrfToken: csrfToken,
    },
  };
}
