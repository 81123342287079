import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

function GoogleLogin() {
  const provider = new GoogleAuthProvider();

  return (
    <button
      className="inline-flex w-full justify-center rounded-md bg-white py-2 px-3 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-martEye-400 disabled:cursor-not-allowed"
      onClick={() => {
        // Use firebase auth to sign in with google
        // Redirect to /
        const auth = getAuth();
        signInWithPopup(auth, provider)
          .then(async (result) => {
            // Get the session cookie from /api/auth/session
            // getAuthCookie();
          })
          .catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            console.error(errorCode, errorMessage, email, credential);
          });
      }}
    >
      <span className="sr-only">Sign in with Gogole</span>
      <svg
        className="h-5 w-5"
        aria-hidden="true"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_8_770)">
          <path
            d="M19.805 10.2303C19.805 9.55056 19.7499 8.86711 19.6323 8.19836H10.2V12.0492H15.6014C15.3773 13.2911 14.6571 14.3898 13.6025 15.0879V17.5866H16.825C18.7173 15.8449 19.805 13.2728 19.805 10.2303Z"
            fill="#4285F4"
          />
          <path
            d="M10.2 20.0007C12.8971 20.0007 15.1716 19.1152 16.8287 17.5866L13.6063 15.088C12.7097 15.6979 11.5522 16.0433 10.2037 16.0433C7.59486 16.0433 5.38285 14.2833 4.58917 11.9169H1.26379V14.4927C2.96139 17.8695 6.41904 20.0007 10.2 20.0007V20.0007Z"
            fill="#34A853"
          />
          <path
            d="M4.5854 11.9169C4.16651 10.6749 4.16651 9.33008 4.5854 8.08811V5.51233H1.2637C-0.154633 8.33798 -0.154633 11.667 1.2637 14.4927L4.5854 11.9169V11.9169Z"
            fill="#FBBC04"
          />
          <path
            d="M10.2 3.95805C11.6257 3.936 13.0036 4.47247 14.0362 5.45722L16.8912 2.60218C15.0834 0.904587 12.684 -0.0287217 10.2 0.000673888C6.41904 0.000673888 2.96139 2.13185 1.26379 5.51234L4.58549 8.08813C5.3755 5.71811 7.59119 3.95805 10.2 3.95805V3.95805Z"
            fill="#EA4335"
          />
        </g>
        <defs>
          <clipPath id="clip0_8_770">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </button>
  );
}

export default GoogleLogin;
