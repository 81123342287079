import {  getAuth, signInWithPopup, OAuthProvider } from "firebase/auth";


function AppleLogin() {

  // Docs https://firebase.google.com/docs/auth/web/apple
  const provider = new OAuthProvider('apple.com');
  provider.addScope('email');
  provider.addScope('name');

  const submit = () => {

    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then(async (result) => {
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The credential that was used.
        const credential = OAuthProvider.credentialFromError(error);

        console.log( error )
    
        // ...
      });

  }

  return (
    <button
      className="inline-flex w-full justify-center rounded-md bg-white py-2 px-3 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-martEye-400 disabled:cursor-not-allowed"
      onClick={submit}
   >
      <span className="sr-only">Sign in with Apple</span>
      <svg
        className="h-5 w-5"
        aria-hidden="true"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_8_753)">
          <path
            d="M18.1599 15.5863C17.8575 16.2851 17.4995 16.9283 17.0847 17.5196C16.5192 18.3257 16.0563 18.8838 15.6995 19.1936C15.1464 19.7022 14.5539 19.9627 13.9193 19.9775C13.4638 19.9775 12.9144 19.8479 12.2749 19.585C11.6333 19.3232 11.0437 19.1936 10.5046 19.1936C9.9392 19.1936 9.3328 19.3232 8.68418 19.585C8.03457 19.8479 7.51125 19.985 7.11114 19.9985C6.50264 20.0245 5.89612 19.7566 5.29071 19.1936C4.9043 18.8566 4.42098 18.2788 3.84199 17.4603C3.22077 16.5863 2.71005 15.5727 2.30994 14.4172C1.88143 13.1691 1.66663 11.9605 1.66663 10.7904C1.66663 9.45009 1.95625 8.29407 2.53635 7.32534C2.99226 6.54721 3.59879 5.9334 4.3579 5.4828C5.11701 5.03219 5.93723 4.80257 6.82054 4.78788C7.30385 4.78788 7.93766 4.93738 8.72529 5.2312C9.5107 5.52601 10.015 5.67551 10.2361 5.67551C10.4014 5.67551 10.9616 5.5007 11.9114 5.15219C12.8095 4.82899 13.5675 4.69517 14.1884 4.74788C15.8711 4.88368 17.1353 5.54699 17.976 6.74202C16.4711 7.65384 15.7267 8.93097 15.7415 10.5693C15.7551 11.8454 16.218 12.9074 17.1279 13.7506C17.5402 14.1419 18.0007 14.4444 18.513 14.6592C18.4019 14.9814 18.2846 15.29 18.1599 15.5863V15.5863ZM14.3008 0.400358C14.3008 1.40059 13.9354 2.3345 13.207 3.19892C12.328 4.22654 11.2648 4.82035 10.1119 4.72665C10.0972 4.60665 10.0887 4.48036 10.0887 4.34765C10.0887 3.38743 10.5067 2.3598 11.249 1.51958C11.6196 1.09416 12.091 0.740434 12.6626 0.458257C13.2329 0.18029 13.7724 0.0265683 14.2798 0.000244141C14.2946 0.133959 14.3008 0.267682 14.3008 0.400345V0.400358Z"
            fill="#111928"
          />
        </g>
        <defs>
          <clipPath id="clip0_8_753">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </button>
  );
}

export default AppleLogin;
