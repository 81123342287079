import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
  sendSignInLinkToEmail,
} from "firebase/auth";
import { useEffect, useRef, useState } from "react";

function EmailLogin() {
  const auth = getAuth();
  const inputRef = useRef<HTMLInputElement>(null);
  const submitRef = useRef<HTMLButtonElement>(null);
  const [email, setEmail] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  // Remove error message
  useEffect(() => {
    // Remove a error message if one exists
    if (errorMsg) {
      setErrorMsg("");
    }
  }, [email]);

  // Remove error message
  useEffect(() => {
    function onKeyDown(e: any) {
      if (
        inputRef.current === document.activeElement &&
        (e.key === "Enter" || e.keyCode === 13)
      ) {
        if (submitRef.current) {
          submitRef.current.click();
        }
      }
    }
    window.addEventListener("keydown", onKeyDown);
  }, []);

  const submit = () => {
    // Set loading
    setLoading(true);
    setDisabled(true);

    let emailValid = false;
    let validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    // Check the email is in a valid format.
    if (!email.match(validRegex)) {
      // Check therre is a input
      if (inputRef.current) {
        inputRef.current.focus();
      }
      // Add in a error message
      setErrorMsg("Please enter a valid email address.");

      // Set loading
      setLoading(false);
      setDisabled(false);

      return;
    }

    // Perform the sendSignInLinkToEmail
    const actionCodeSettings = {
      url: window.location.href,
      handleCodeInApp: true,
    };

    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(async (result) => {
        // Save the email locally so you don't need to ask the user for it again
        window.localStorage.setItem("emailForSignIn", email);
        setLoading(false);
        setDisabled(true);
        setSuccessMsg('We have just sent you a link to sign you in');
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        setDisabled(false);
        setLoading(false);
      });
  };

  // Check if the user has been signed in
  if (isSignInWithEmailLink(auth, window.location.href)) {
    let emailAddress: string =
      window.localStorage.getItem("emailForSignIn") || "";

    if (!emailAddress) {
      // TODO Need to create a popup to re-ask for the email address if used on a different browser!
      emailAddress =
        window.prompt("Please provide your email for confirmation") || "";
    }

    // The client SDK will parse the code from the link for you.
    signInWithEmailLink(auth, emailAddress, window.location.href)
      .then(async (result) => {
        // Get the session cookie from /api/auth/session
        //getAuthCookie();

        // Remove the email for signin
        window.localStorage.removeItem("emailForSignIn");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      <div>
        <label htmlFor="email" className="sr-only">
          Email address
        </label>
        <div className="mt-2">
          <input
            ref={inputRef}
            placeholder="Email"
            type="email"
            autoComplete="email"
            required
            disabled={disabled}
            value={email}
            className="block w-full rounded-md border-0 px-3 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-martEye-400 disabled:cursor-not-allowed disabled:text-gray-600/75 sm:text-sm sm:leading-6"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        {errorMsg && (
          <p className="mt-2 text-sm text-red-600 dark:text-red-500">
            {errorMsg}
          </p>
        )}
      </div>

      <div className="mt-4">
        <button
          ref={submitRef}
          type="submit"
          disabled={disabled}
          className="flex inline-flex w-full items-center justify-center rounded-md bg-martEye-400 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-martEye-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-martEye-400 disabled:cursor-not-allowed disabled:bg-slate-200 disabled:text-black"
          onClick={submit}
        >
          Sign in
          {loading && (
            <>
              <svg
                aria-hidden="true"
                role="status"
                className="ml-2 inline h-4 w-4 animate-spin fill-martEye-400 text-slate-200"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span className="sr-only">Loading...</span>
            </>
          )}
        </button>
        {successMsg && 
          <p className="mt-2 text-center text-sm text-martEye-400">
            {successMsg}
          </p>
        }

      </div>
    </>
  );
}

export default EmailLogin;
